export default [
    {
        country: 'Sweden',
        domain: 'https://kortio.se',
        prismic: 'sv-se',
        hreflang: 'sv-se',
        icon: '/flags/sv.png'
    },
    {
        country: 'Norway',
        domain: 'https://kortio.com',
        prismic: 'no',
        hreflang: 'no-no',
        icon: '/flags/no.png'
    },
];
