<template>
    <!-- TODO: Do anything special for application forms? -->
    <div v-if="hasApplicationForm || isOutLink" class="btn-div">
        <nuxt-link
            v-if="hasApplicationForm"
            :to="applicationFormUrl"
            class="btn"
            :class="[
                'btn-' + background,
                'btn-' + size
            ]"
        >
            {{ ctaText }}
            <i v-if="icon" :class="icon" />
        </nuxt-link>
        
        <a
            v-else-if="isOutLink"
            :href="outLinkUrl"
            target="_blank"
            class="btn"
            :class="[
                'btn-' + background,
                'btn-' + size
            ]"
        >
            {{ ctaText }}
            <i v-if="icon" :class="icon" />
        </a>
        <span v-if="$isNorway()" class="compliance">Annonselenke</span>
    </div>
</template>
<script>
export default {
    props: {
        background: {
            type: String,
            default: '',
            required: false
        },
        size: {
            type: String,
            default: '',
            required: false
        },
        icon: {
            type: String,
            default: undefined,
            required: false,
        },
        link: {
            type: Object,
            required: true
        },
        text: {
            type: String,
            required: false,
            default: null
        }
    },
    computed: {
        ctaText() {
            
            if (this.text !== null) {
                return this.text;
            }

            return this.$translate('cta_apply', 'Ansök nu');
        },
        hasApplicationForm() {

            if (this.$isNorway()) {

                // never any application forms in Norway,
                // for now atleast...
                return false;
            }

            if (this.link.type === 'lender') {
                // lenders can't have applications form
                return false;
            }

            return !this.isOutLink;
        },

        // NOTE: Hard coded for Sweden
        // NOTE: only used in Sweden?
        applicationFormUrl() {
            return `/kort/${this.link.uid}/ansok/`;
        },
        isOutLink() {

            if (this.link.type === 'lender') {
                return Boolean(this.link.data.out_link_url);
            }

            return this.link.data.application_type === 'out_link';
        },
        outLinkUrl() {
            return `/out/${this.link.id}/`;
        },
    }
};
</script>
<style lang="scss" scoped>
    .card-sticky-mobile::v-deep {
        .compliance {
            background-color: $white;
        }
    }
    .compliance {
        display: block;
        text-align: center;
        padding: 8px 0;
        font-size: .8em;
        color: $muted;
        font-weight: lighter;
    }
</style>
