import { upperCase } from 'lodash';
export default ({ app }, inject) => {

    inject('isSweden', isSweden);
    inject('isNorway', isNorway);

    const isDebug = () => {
        return upperCase(process.env.APP_DEBUG) === 'TRUE';
    };
    inject('isDebug', isDebug);

    const isProduction = () => {
        return !isDebug();
    };
    inject('isProduction', isProduction);

    const timestamp = (dateString) => {
        let timestamp = dateString.split('T')[0];
        return timestamp;
    };
    inject('timestamp', timestamp);


    const truncateString = (string, maxChars = 250) => {
        if (maxChars > string.length) {
            return string.substring(0, maxChars);
        }
        return string.substring(0, maxChars) + ' [...]';
    };
    inject('truncateString', truncateString);

    const formatSliceType = (type) => {
        if (type.includes('_')) {
            let split = type.split('_');
            for (let i = 0; i < split.length; i++) {
                split[i] = split[i].charAt(0).toUpperCase() + split[i].slice(1);
            }
            return split.join('');
        }

        return type;
    };

    inject('formatSliceType', formatSliceType);

    const formatBoolean = (bool) => {
        if (bool) {
            return app.$translate('yes', 'Ja'); 
        }
        return app.$translate('no', 'Nej');
    };
    inject('formatBoolean', formatBoolean);

    const validateText = (textElement) => {
        if (typeof(textElement) === 'string') {
            return Boolean(textElement?.trim());
        }
        return Boolean(app.$prismic.asText(textElement)?.trim());
    };
    inject('validateText', validateText);
    
    inject('getCategoryData', (card, categoryKey) => {
        categoryKey = categoryKey ?? 'general';
        const generalData = card.data.category_data
            .find(category => category.key === 'general');
        
        const categoryData = card.data.category_data
            .find(category => category.key === categoryKey);
        
        const getValue = (dataKey) => {
            if (categoryData && validateText(categoryData[dataKey])) {
                return categoryData[dataKey];
            }
            if (generalData && validateText(generalData[dataKey])) {
                return generalData[dataKey];
            }
            return null;
        };

        return {
            // eslint-disable-next-line 
            usp_label: getValue('usp_label'),
            pros: getValue('pros'),
            cons: getValue('cons'),
            // eslint-disable-next-line 
            usp_box_title: getValue('usp_box_title'),
            // eslint-disable-next-line 
            usp_box_description: getValue('usp_box_description'),
        };
    });

};

const getPrismicLocale = () => {
    return process.env.PRISMIC_LOCALE;
};

const isSweden = () => {
    return getPrismicLocale() === 'sv-se';
};
const isNorway = () => {
    return getPrismicLocale() === 'no';
};