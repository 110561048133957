import getDomainFromPrismicLocale from './get-domain-from-prismic-locale.js';
export default function (document, withDomain = false) {
    try {

        const documents = require('@/documents.json');
        const documentHelper = documents[document.id];
        const slug = documentHelper.slug;

        if (withDomain) {
            const domain = getDomainFromPrismicLocale(documentHelper.prismicLocale);
            return `${domain}${slug}`;
        }

        return slug;
    }
    catch (e) {
        return '/undefined';
    }
}
