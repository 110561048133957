import { size, keyBy } from 'lodash';
import { replace as replaceHelper, queryAll } from '@swegaming-ab/nuxtjs-helpers';
import navigationQuery from '@/plugins/navigation-query.js';
export const state = () => ({
    cards: {},
    translations: {},
    postCategories: {},
    authors: {},
    questionAnswerCategories: {},
    cardsToCompare: [],
    lists: [],
    settings: {}
});

export const mutations = {
    SET_STATE(state, { key, value }) {
        state[key] = replaceHelper(value, state.currentPrismicLocale);
        // state[key] = value;
    },

    ADD_CARD_TO_COMPARE(state, cardId) {
        state.cardsToCompare.push(cardId);
    },

    REMOVE_CARD_FROM_COMPARE(state, cardId) {
        for (let i = 0; i < state.cardsToCompare.length; i++) {
            if (state.cardsToCompare[i] === cardId) {
                state.cardsToCompare.splice(i, 1);
                break;
            }
        }
    },

    CLEAR_CARDS_TO_COMPARE(state) {
        state.cardsToCompare.splice(0);
    }
};

export const actions = {
    // eslint-disable-next-line complexity
    async fetchStates({ commit }, {$prismic}) {
        try {
            if (size(this.state.navigation) === 0) {
                const navigation = await $prismic.api.getSingle('navigation', {
                    lang: process.env.PRISMIC_LOCALE, graphQuery: navigationQuery()
                });

                if (navigation) {
                    commit('SET_STATE', {
                        key: 'navigation',
                        value: navigation.data
                    });
                }
            }

            if (size(this.state.footer) === 0) {
                const footer = await $prismic.api.getSingle('footer', {
                    lang: process.env.PRISMIC_LOCALE, fetchLinks: ['page.icon', 'page.parent']
                });

                if (footer) {
                    commit('SET_STATE', {
                        key: 'footer',
                        value: footer.data
                    });
                }
            }

            if (size(this.state.translations) === 0) {
                const translations = await $prismic.api.getSingle('translations', { 
                    lang: process.env.PRISMIC_LOCALE 
                });             

                Object.keys(translations.data).forEach(key => {
                    if (! translations.data[key]) {
                        delete translations.data[key];
                    }
                });

                if (translations) {
                    commit('SET_STATE', {
                        key: 'translations',
                        value: translations.data
                    });
                }
            }

            if (size(this.state.cards) === 0) {
                const cards = await queryAll(
                    $prismic.api,
                    $prismic.predicates.at('document.type', 'card'),
                    { lang: process.env.PRISMIC_LOCALE }
                );

                cards.forEach(card => {

                    cleanUpDocumentData(card);

                    delete card.data.review;
                    delete card.data.faq;
                    delete card.data.quote_header;
                    delete card.data.quote_text;
                    delete card.data.quote_by;
                    delete card.data.cards;
                    delete card.data.banner_color;
                    delete card.data.seo_title;
                    delete card.data.seo_description;
                    delete card.data.core_api.core_data;
                });

                if (cards) {
                    commit('SET_STATE', {
                        key: 'cards',
                        value: keyBy(cards, 'id')
                    });
                }
            }

            if (size(this.state.lists) === 0) {
                const lists = await queryAll(
                    $prismic.api,
                    $prismic.predicates.at('document.type', 'list'),
                    { lang: process.env.PRISMIC_LOCALE }
                );

                lists.forEach(list => {
                    cleanUpDocumentData(list);
                    list.data.cards.forEach(iterator => {
                        cleanUpLinkData(iterator.card);
                    });
                });

                commit('SET_STATE', {
                    key: 'lists',
                    value: keyBy(lists, 'id')
                });
            }

            if (size(this.state.postCategories) === 0) {
                const postCategories = await queryAll(
                    $prismic.api,
                    $prismic.predicates.at('document.type', 'post_category'),
                    { lang: process.env.PRISMIC_LOCALE }
                );
                
                postCategories.forEach(category => {
                    cleanUpDocumentData(category);
                });

                if (postCategories) {
                    commit('SET_STATE', {
                        key: 'postCategories',
                        value: keyBy(postCategories, 'id')
                    });
                }
            }

            if (size(this.state.questionAnswerCategories) === 0) {
                const questionAnswerCategories = await queryAll(
                    $prismic.api,
                    $prismic.predicates.at('document.type', 'question_answer_category'),
                    { lang: process.env.PRISMIC_LOCALE }
                );

                questionAnswerCategories.forEach(category => {
                    cleanUpDocumentData(category);
                });

                if (questionAnswerCategories) {
                    commit('SET_STATE', {
                        key: 'questionAnswerCategories',
                        value: keyBy(questionAnswerCategories, 'id')
                    });
                }
            }

            if (size(this.state.authors) === 0) {
                const authors = await queryAll(
                    $prismic.api,
                    $prismic.predicates.at('document.type', 'author'),
                    { lang: process.env.PRISMIC_LOCALE }
                );

                authors.forEach(author => {
                    cleanUpDocumentData(author);

                    delete author.data.seo_title;
                    delete author.data.seo_description;
                    delete author.data.page_preamble;
                    delete author.data.page_content;
                });

                if (authors) {
                    commit('SET_STATE', {
                        key: 'authors',
                        value: keyBy(authors, 'id')
                    });
                }
            }
            
            if (size(this.state.settings) === 0) {
                const settings = await $prismic.api.getSingle('settings', { 
                    lang: process.env.PRISMIC_LOCALE 
                });

                if (settings) {
                    commit('SET_STATE', {
                        key: 'settings',
                        value: settings.data
                    });
                }
            }
        }
        catch (e) {
            console.log(e);
        }
    }
};

const cleanUpDocumentData = (document) => {
    delete document.url;
    delete document.href;
    delete document.first_publication_date;
    delete document.last_publication_date;
    delete document.tags;
    delete document.slugs;
    delete document.linked_documents;
    delete document.alternate_languages;
    if (document.type !== 'card') {
        delete document.lang;
    }
};

const cleanUpLinkData = (link) => {
    delete link.tags;
    delete link.lang;
    delete link.slug;
    delete link.first_publication_date;
    delete link.last_publication_date;
};